import React from 'react'
import { useState, useEffect } from 'react'
import Logo from '../assets/images/logo.png'


const Header = (props) => {
  const [active, setActive] = useState(true)
  const [home, setHome] = useState(false)
  const [about, setAbout] = useState(false)
  const [services, setServices] = useState(false)
  const [impressum, setImpressum] = useState(false)
  const [serviceSelectState, setServiceSelectState] = useState(false)
  const [consulting, setConsulting] = useState(false)
  const [development, setDevelopment] = useState(false)
  const [production, setProduction] = useState(false)


  useEffect(() => {
    applyRoute();
    
  })
  
  

  function toggle(){
    setActive(!active)
  }

  function selectService(){
    setServiceSelectState(!serviceSelectState)
  }

  function applyRoute()
  {
    switch (props.route) {
      case 'home':
        setHome(true);
        break;
      case 'about':
        setAbout(true);
        break;
      
      case 'consulting':
        setConsulting(true);
        break;
      case 'development':
        setDevelopment(true);
        break;
      case 'production':
        setProduction(true);
        break;
      case 'impressum':
        setImpressum(true);
        break;
      default:
        console.log('route is not defined --header.js')
        break;
    }
  }
  
  
  return (
    <>
      <nav className='navbar'>
          <img src={Logo} alt="Logo.png"/>


          
          <div className="menu">
            <a href="../" className={`${home? "isActive":""}`}>Home</a>
            <a href="../about" className={`${about? "isActive":""}`}>About Us</a>
            <a onClick={selectService} className={`${serviceSelectState? "isActive":""}`}>Services</a>
            <a href="../impressum" className={`${impressum? "isActive":""}`}>Impressum</a>
            <a href="mailto:some@student.xu-university.de">Contact</a>
          </div>

          <button className={`dropdown ${active? "":"isActive"}`} onClick={toggle} >
            <span></span>
            <span></span>
            <span></span>
          </button>
 
      </nav>

      <div className={`services ${((serviceSelectState && !active)||(window.innerWidth>820 && serviceSelectState))? "isActive":""}`}>
        <a href="../service/consulting" className={`${consulting? "isActive":""}`}>Constulting</a>
        <a href="../service/development" className={`${development? "isActive":""}`}>Development</a>
        <a href="../service/production" className={`${production? "isActive":""}`}>Production</a>
      </div>

      <div className="headWrap" style={{
          backgroundImage: `url("${props.picture}")`
        }}>
        <div className="overlay"></div>
        <h1 className="text">{props.text}</h1>
      </div>

      <nav className={`dropNav ${active? "":"isActive"}`}>
          <div className="menu">
            <a href="../" className={`${home? "isActive":""}`}>Home</a>
            <a href="../about" className={`${about? "isActive":""}`}>About Us</a>
            <a onClick={selectService} className={`${serviceSelectState? "isActive":""}`}>Services</a>
            <a href="../impressum" className={`${impressum? "isActive":""}`}>Impressum</a>
            <a href="mailto:some@student.xu-university.de">Contact</a>
          </div>

      </nav>
    </>
  )
}

export default Header