import React from 'react'
import img from '../../assets/images/landing2.jpg'
import Header from '../../components/Header'
import { ImageCut } from '../../components/ImageCut'
import LeftRightImage1 from '../../assets/images/landing2.jpg'

const Impressum = () => {

  return (
    <div className='Impressum'>
        <Header picture={`${img}`} text='Impressum' route='impressum'/>

        <main>
        <ImageCut isFlipped={false} isMirrowed={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <ImageCut isFlipped={true} isMirrowed={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        
        <ImageCut isFlipped={true} isMirrowed={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <ImageCut isFlipped={false} isMirrowed={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <ImageCut isFlipped={false} isMirrowed={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        </main>
    </div>
  )
}

export default Impressum