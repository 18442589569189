import React from 'react'
import { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardMedia } from '@mui/material';

export const ImageCut = (props) => {
    const [isMirrowed, setIsMirrowed] = useState(false)
    const [width, setWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        applyMirrow();
        
    })

    React.useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        
      }, []);

    function applyMirrow(){
        if (props.isMirrowed){
            setIsMirrowed(true)
        }else{
            setIsMirrowed(false)
        }
    }

    let component;
    
    function leftRight(props) {

        if (width <= 630){
            return(
                <div className='cutCard'>
                    <Card className='Card' sx={{ 
                        maxWidth: 350,
                        borderRadius: 7,
                        zoom: .85,
                        color: 'var(--main-ttextColor)',
                        backgroundColor: 'var(--section-cards)',
                        boxShadow: '4px 8px 15px 5px var(--main-shadow)'
                    }}>
                      <CardMedia
                        component="img"
                        height="120"
                
                        image={props.picture}
                        alt="Domain image"
                      />
                      <CardContent >
                        <center>
                            <Typography fontWeight='fontWeightMedium' gutterBottom variant="h5" component="div">
                            {props.headline}
                            </Typography>
                        </center>
                        <Typography variant="body2">
                          {props.text}
                        </Typography>
                      </CardContent>
                      
                    </Card>
                </div>
            )


        }else return(
            
            <div className={`image-cut ${isMirrowed? "isMirrowed":""}`}>
                
                <div className='text-wrap'>
                    <h3>{props.headline}</h3>
                    <p>{props.text}</p>
                    
                </div>
                
                <div className='imageCut' style={{border: 5}}>
                    <img src={props.picture} alt="right"></img>
                    
                </div>
            </div>
        )
        
        
    }

    function rightLeft(props) {
        
        if (width <= 630){
            return(
                <div className='cutCard'>
                    <Card className='Card' sx={{ 
                        maxWidth: 350,
                        borderRadius: 7,
                        zoom: .85,
                        color: 'var(--main-ttextColor)',
                        backgroundColor: 'var(--section-cards)',
                        boxShadow: '4px 8px 15px 5px var(--main-shadow)'
                    }}>
                      <CardMedia
                        component="img"
                        height="120"
                
                        image={props.picture}
                        alt="Domain image"
                      />
                      <CardContent >
                        <center>
                            <Typography fontWeight='fontWeightMedium' gutterBottom variant="h5" component="div">
                            {props.headline}
                            </Typography>
                        </center>
                        <Typography variant="body2">
                          {props.text}
                        </Typography>
                      </CardContent>
                      
                    </Card>
                </div>
            )


        }else return(
            
            <div className={`image-cut   flipped ${isMirrowed? "isMirrowed":""}`}>
                
                <div className='imageCut' style={{border: 5}}>
                    <img src={props.picture} alt="right"></img>
                    
                </div>

                <div className='text-wrap'>
                    <h3>{props.headline}</h3>
                    <p>{props.text}</p>
                    
                </div>
                
            </div>
        )
    }

    if(props.isFlipped){
        component = rightLeft(props)
    }else{
        component = leftRight(props)
    }

  return (
    <>
    {component}
    </>
  )
}
