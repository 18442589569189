import React from 'react'
import Header from '../../../components/Header'

import LeftRight from '../../../components/LeftRight'
import img from '../../../assets/images/team.jpg'
import LeftRightImage1 from '../../../assets/images/landing2.jpg'

const Production = () => {
  return (
    <div className='Production'>
      <Header picture={`${img}`} text='Production' route='production'/>

      <main>
        <LeftRight isFlipped={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        <LeftRight isFlipped={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
        
      </main>
    </div>
  )
}

export default Production