
import React from 'react'

const LeftRight = (props) => {
   
    let component;

    
    function leftRight(props) {
        
        return(
            <section id="service" className="left-right">
                
                <img src={props.picture} width='10' alt="Logo.png"/>
                
                <div className="text">
                    <h2>{props.headline}</h2>
                    <p>{props.text}</p>
                </div>
            </section>
        )
    }

    function rightLeft(props) {
        
        return(
            <section id="service" className="right-left">
                <div className="text">
                    <h2>{props.headline}</h2>
                    <p>{props.text}</p>
                </div>
                <img src={props.picture} width='10' alt="Logo.png"/>
            </section>
        )
    }

    if(props.isFlipped){
        component = (window.innerWidth <= 680) ? leftRight(props) : rightLeft(props)
    }else{
        component = leftRight(props)
    }
    

  return (
    <>
    {component}
    </>
  )
}

export default LeftRight