import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { CardMedia } from '@mui/material';




const DomainCard = (props) => {


    return (

      <div className='card'>
        <Card className='Card' sx={{ 
            maxWidth: 350,
            borderRadius: 7,
            zoom: .85,
            color: 'var(--main-ttextColor)',
            backgroundColor: 'var(--section-cards)',
            boxShadow: '4px 8px 15px 5px var(--main-shadow)'
        }}>
          <CardMedia
            component="img"
            height="120"
            
            image={props.picture}
            alt="Domain image"
          />
          <CardContent >
            <div className='text-wrap'>
              <center><h3>{props.headline}</h3></center>
              <p>{props.text}</p>
                    
            </div>
          </CardContent>
          <CardActions style={{justifyContent: 'center'}}>
            
            <Button medium variant="outlined" href={props.link}>
                Learn More
            </Button>
            
          </CardActions>
        </Card>
      </div>

        
    );
}

export default DomainCard