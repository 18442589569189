import React from 'react'
import Header from '../../components/Header'
import img from '../../assets/images/hardware-software-interface.jpg'
import DomainCard from '../../components/DomainCard'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { Grid } from '@mui/material'
import LeftRightImage1 from '../../assets/images/landing2.jpg'
import { ImageCut } from '../../components/ImageCut'
import LeftRight from '../../components/LeftRight'
import DataTable from '../../components/DataTable'

const Home = () => {
  return (
    <div className='Home'>
        <Header picture={`${img}`} text='Alle Componenten:' route='home'/>

        <main>
          <section style={{marginTop: 50, zoom: .95}}>
            <LeftRight isFlipped={false} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
          </section>
          
          <section style={{marginTop: 70, marginBottom: 80}}>
            <Grid2 container columnGap={5} rowGap={5} justifyContent="center">
              <Grid item>
                <DomainCard picture={img} link="./service/development" headline="Development" text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.  Some More text.... Bla bla bla Some More text.... Bla bla bla Some More text.... Bla bla bla Some More text.... Bla bla bla'/>
              </Grid>
              <Grid item>
                <DomainCard picture={img} link="./service/production" headline="Production" text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision. Some More text.... Bla bla bla Some More text.... Bla bla bla Some More text.... Bla bla bla Some More text.... Bla bla bla'/>
              </Grid>
              <Grid item>
                <DomainCard picture={img} link="./service/consulting" headline="Consulting" text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision. Some More text.... Bla bla bla Some More text.... Bla bla bla Some More text.... Bla bla bla Some More text.... Bla bla bla'/>
              </Grid>
            </Grid2>
          </section>

          
          <ImageCut isFlipped={true} isMirrowed={true} picture={img} headline='Web-Development' text='Our bussines is to bring your idea and project to life. We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
          <ImageCut isFlipped={false} isMirrowed={true} picture={LeftRightImage1} headline='Web-Development' text='Our bussines is to bring your idea and project to life.  We will develop the side you have been always looking for. Depending on your project we will find fitting work- and colaboration modes to suit your vision.'/>
          
          
          <section style={{marginTop: 50}}>
            <DataTable reverse={false} picture={LeftRightImage1} height={180} colums={["Technische Angaben:"," Luftstrom "," "," "]} rows={[["Luftvolumenstrom SielentMode", "m3/h", "375", "programmierbar"],["Luftvolumenstrom Maximal", "m3/h", "750", "programmierbar"],["Schalldruckpegel", "dB(A)", "23-40", "Drehzahl abhängig (SilentMode)"], ["Schaltstufen", "AutoMode", "SilentMode", "MaxMode"]]}></DataTable>

            <DataTable reverse={true} picture={LeftRightImage1} height={170} colums={["Technische Angaben:"," Form "," ",""]} rows={[["Gewicht", "Kg", "28", ""],["Abmessungen", "mm", "350x400x650", "ohne Rollen"],["Montage", "Fix", "Mobil", ""]]}></DataTable>
          </section>

          

        </main>
    </div>
  )
}

export default Home