import React from 'react'
import {Route, Routes} from 'react-router-dom';

import Home from '../Pages/Home/Home'
import About from '../Pages/About/About';
import Consulting from '../Pages/Offers/Consulting/Consulting'
import Development from '../Pages/Offers/Development/Development'
import Production from '../Pages/Offers/Production/Production'
import Impressum from '../Pages/Impressum/Impressum'
import { createTheme, ThemeProvider } from '@mui/material';
import Footer from './Footer';


const App = () => {

const phone = 630;
const tablet = 850;
const phoneZoomLevel = 0.55;
const tabletZoomLevel = 0.75; 
const desktopZoomLevel = 0.90;
const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

if (width <= phone) {
  document.body.style.zoom = phoneZoomLevel;
}else if (width > tablet) {
  document.body.style.zoom = desktopZoomLevel;
}else{
  document.body.style.zoom = tabletZoomLevel;
}
  
const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },});
  return (
    <ThemeProvider theme={theme}>
    <div className='App'>
      <Routes basename={process.env.PUBLIC_URL}>
        <Route path="/" element={<Home/>}/>
        <Route path="/service/production" element={<Production/>}/>
        <Route path="/service/development" element={<Development/>}/>
        <Route path="/service/consulting" element={<Consulting/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/impressum" element={<Impressum/>}/>
      </Routes>
      <Footer/>


    </div>
    </ThemeProvider>
  )
}

export default App