import React from 'react'
import Header from '../../../components/Header'


import img from '../../../assets/images/team.jpg'
import LeftRightImage1 from '../../../assets/images/landing2.jpg'
import DataTable from '../../../components/DataTable'


const Consulting = () => {
  return (
    <div className='Consulting'>
        
      <Header picture={`${img}`} text='Consulting' route='consulting'/>

      <main>
        <section style={{marginTop: 50, zoom: 1}}>
          <DataTable reverse={false} picture={LeftRightImage1} height={180} colums={["Technische Angaben:"," Luftstrom "," "," "]} rows={[["Luftvolumenstrom SielentMode", "m3/h", "375", "programmierbar"],["Luftvolumenstrom Maximal", "m3/h", "750", "programmierbar"],["Schalldruckpegel", "dB(A)", "23-40", "Drehzahl abhängig (SilentMode)"], ["Schaltstufen", "AutoMode", "SilentMode", "MaxMode"]]}></DataTable>

          <DataTable reverse={true} picture={LeftRightImage1} height={170} colums={["Technische Angaben:"," Form "," ",""]} rows={[["Gewicht", "Kg", "28", ""],["Abmessungen", "mm", "350x400x650", "ohne Rollen"],["Montage", "Fix", "Mobil", ""]]}></DataTable>
        </section>
      </main>
    </div>
    
  )
}

export default Consulting