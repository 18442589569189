import React from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



const DataTable = (props) => {


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderBottom: '1px solid var(--main-ttextColor)',
    color: 'var(--main-ttextColor)',
    fontSize: '1em',
    

    [`&.${tableCellClasses.head}`]: {
      backgroundColor: getComputedStyle(document.body).getPropertyValue('--main-hfilter2'),
      color: theme.palette.common.white,
      border: 0,
      
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: "left",
      backgroundColor: "var(--section-cards)"
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,

    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


  
  return (
    <section className='dataTable' style={{flexDirection: props.reverse? "row-reverse":"row"}}>
      <Paper elevation={1}>
        <picture>
          <img src={props.picture} height={props.height} alt='table'/>
        </picture>
      </Paper>

      
      <div className='table'>
        <TableContainer elevation={1} sx={{zoom: 0.75}} >
          <Table sx={{ Width: 700}} aria-label="customized table">
            <TableHead>
              <StyledTableRow key={0}>
                {
                  props.colums.map(colum =>(
                    <StyledTableCell>{colum}</StyledTableCell>
                  ))
                }
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row, index) => (
                <StyledTableRow key={index +1}>
                  {
                    row.map((data)=>(
                      <StyledTableCell align="right">{data}</StyledTableCell>
                      )
                    )
                  }
                  
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>   
        
        
    </section>
  )
}

export default DataTable